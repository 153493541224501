// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accommodations-js": () => import("../src/pages/accommodations.js" /* webpackChunkName: "component---src-pages-accommodations-js" */),
  "component---src-pages-announcement-js": () => import("../src/pages/announcement.js" /* webpackChunkName: "component---src-pages-announcement-js" */),
  "component---src-pages-covid-19-js": () => import("../src/pages/covid-19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-gallery-js": () => import("../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-registry-js": () => import("../src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */)
}

